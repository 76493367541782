/* eslint-disable react/jsx-boolean-value */
import React, { useState } from 'react'
import { graphql } from 'gatsby'

// Components
import SEO from 'components/SEO'
import Layout from 'components/Layout'
import ButtonAlt from 'components/Elements/ButtonAlt'
import CustomBreadCrumb from 'components/Common/Breadcrumb'
import HouseContent from 'components/Common/HouseContent'
import Share from 'components/Aanbod/Share'
import Preview from 'components/Home/Preview'
import AanbodImages from 'components/Aanbod/AanbodImages'
import WoningSpecificaties from 'components/Aanbod/WoningSpecificaties'
import { Paragraph, SecondHeader } from 'components/StyledComponents'
import ContactModal from 'components/Common/ContactModal'

// Third Party
import styled from 'styled-components'
import parse from 'html-react-parser'

import thumbnail from 'img/thumbnail.png'

const Aanbod = styled.div``

const Separator = styled.div`
  height: 1px;
  width: 100%;
`

const StyledButtonAlt = styled(ButtonAlt)`
  font-size: 18px;
`

const WoningDetailTemplate = ({
  data: {
    woning,
    otherWoningen: { edges: otherWoningen },
    allWoningen: { edges: allWoningen },
  },
  location,
  pageContext,
}) => {
  const [bezIsOpen, setBezOpen] = useState(false)

  const formatter = new Intl.NumberFormat('nl-NL', {
    style: 'currency',
    currency: 'EUR',
    minimumFractionDigits: 0,
  })

  const conditieChecker = () => {
    let returnString = ''

    if (woning.recapWoning.koopconditie) {
      if (woning.recapWoning.koopconditie === 'VRIJ_OP_NAAM') {
        returnString = 'v.o.n.'
      } else if (woning.recapWoning.koopconditie === 'KOSTEN_KOPER') {
        returnString = 'k.k.'
      } else {
        returnString = 'N/A'
      }
    } else {
      returnString = ''
    }

    return returnString
  }

  return (
    <Layout>
      {/* {() => ( */}
        <>
          <SEO
            seo={woning.seo}
            socialImage={
              woning.recapWoning.image !== null
                ? woning.recapWoning.image
                : thumbnail
            }
          />
          <Aanbod>
            <CustomBreadCrumb
              data={{ title: woning.recapWoning.address }}
              className="py-2"
              pageContext={pageContext}
            />
            <div className="container">
              <div className="row pt-5">
                <div className="col-lg-7 pl-lg-3">
                  <HouseContent
                    data={{
                      recapWoning: {
                        content: `<h1>${woning.recapWoning.address}</h1>`,
                      },
                    }}
                  />
                  <div className="d-flex justify-content-between pb-3">
                    <Paragraph className="font-size-xm">
                      {woning.recapWoning.city}
                    </Paragraph>
                    {
                      {
                        BESCHIKBAAR: (
                          <Paragraph className="font-size-xm font-weight-xl">{`${formatter.format(
                            woning.recapWoning.price
                          )},- ${
                            woning.recapWoning.koop
                              ? `${conditieChecker()}`
                              : `p/m.`
                          }`}</Paragraph>
                        ),
                        VERKOCHT_ONDER_VOORBEHOUD: (
                          <Paragraph className="font-size-xm font-weight-xl text-uppercase">
                            Verkocht onder voorbehoud
                          </Paragraph>
                        ),
                        VERKOCHT: (
                          <Paragraph className="font-size-xm font-weight-xl text-uppercase">
                            Verkocht
                          </Paragraph>
                        ),
                        VERHUURD_ONDER_VOORBEHOUD: (
                          <Paragraph className="font-size-xm font-weight-xl text-uppercase">
                            Verhuurd onder voorbehoud
                          </Paragraph>
                        ),
                        ONDER_BOD: (
                          <Paragraph className="font-size-xm font-weight-xl">{`ONDER BOD | ${formatter.format(
                            woning.recapWoning.price
                          )},- ${
                            woning.recapWoning.koop ? `k.k.` : `p/m.`
                          }`}</Paragraph>
                        ),
                      }[woning.recapWoning.status]
                    }
                  </div>
                  {woning.recapWoning.image !== null && (
                    <AanbodImages data={woning} className="py-2" />
                  )}
                  <Separator className="color-background-main mt-2 mb-2 mb-lg-4" />
                  <WoningSpecificaties
                    setOpen={setBezOpen}
                    data={woning}
                    className="pb-5 pt-4 pt-lg-0 d-flex d-lg-none justify-content-center justify-content-lg-end"
                  />
                  <SecondHeader className="font-size-xm font-weight-xl pb-3 text-uppercase">
                    Informatie
                  </SecondHeader>
                  <Paragraph className="w-100">
                    {parse(
                      `<p>${woning.recapWoning.description.replace(
                        /(?:\r\n|\r|\n)/g,
                        `<br>`
                      )}</p>`
                    )}
                  </Paragraph>
                  <StyledButtonAlt
                    to="/contact"
                    className="d-none d-lg-inline-block"
                  >
                    Neem contact op
                  </StyledButtonAlt>
                </div>
                <div className="col-12 pl-lg-5 pt-5 pt-lg-0 col-lg-5 pr-lg-3">
                  <Share
                    setOpen={setBezOpen}
                    location={location}
                    data={woning}
                    className="pb-5 pt-4 pt-lg-0 justify-content-center justify-content-lg-end"
                  />
                  <WoningSpecificaties
                    setOpen={setBezOpen}
                    data={woning}
                    className="pb-5 pt-4 pt-lg-0 d-none d-lg-flex justify-content-center justify-content-lg-end"
                  />
                </div>
              </div>
              <Preview
                data={otherWoningen}
                title="Vergelijkbare woningen"
                loadMoreButton={true}
                className="py-3 py-lg-5 mb-lg-5"
              />
            </div>
            <ContactModal
              currentObject={woning}
              type="woning"
              title="Bezichtiging plannen"
              woningen={allWoningen}
              id={3}
              modalIsOpen={bezIsOpen}
              setModalOpen={setBezOpen}
            />
          </Aanbod>
        </>
      {/* )} */}
    </Layout>
  )
}

export const pageQuery = graphql`
  query ($databaseId: Int!) {
    woning: wpWoning(databaseId: { eq: $databaseId }) {
      slug
      uri
      title
      seo {
        canonical
        cornerstone
        focuskw
        metaDesc
        metaKeywords
        metaRobotsNofollow
        metaRobotsNoindex
        opengraphAuthor
        opengraphDescription
        opengraphModifiedTime
        opengraphPublisher
        opengraphPublishedTime
        opengraphSiteName
        opengraphTitle
        opengraphType
        opengraphUrl
        title
        twitterDescription
        twitterTitle
        opengraphImage {
          sourceUrl
        }
        twitterImage {
          sourceUrl
        }
      }
      recapWoning {
        image
        city
        address
        gallery
        typeHouse
        typeOfHouse
        bathrooms
        country
        description
        houseId
        image
        koop
        koopconditie
        location
        buildYear
        numberRooms
        numberSleeprooms
        price
        province
        status
        totaleWoonoppervlakte
        totaleOppervlakte
        type
        zipcode
      }
    }
    allWoningen: allWpWoning {
      edges {
        node {
          title
          slug
          databaseId
        }
      }
    }
    otherWoningen: allWpWoning(
      filter: { databaseId: { ne: $databaseId } }
      limit: 6
    ) {
      edges {
        node {
          title
          slug
          databaseId
          recapWoning {
            image
            city
            address
            gallery
            typeHouse
            typeOfHouse
            bathrooms
            country
            description
            houseId
            image
            koop
            koopconditie
            location
            buildYear
            numberRooms
            numberSleeprooms
            price
            province
            status
            totaleWoonoppervlakte
            totaleOppervlakte
            type
            zipcode
          }
        }
      }
    }
  }
`

export default WoningDetailTemplate
